<template>
    <div style="position: relative">
        <el-container>
            <el-main>
                <head-layout head-title="各模块应用统计分析基础配置" :showIcon="false" :head-btn-options="headBtnOptions"
                    @head-add="headAdd" @head-romve="headRomve" @search="search">
                </head-layout>
                <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns"
                    @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
                <grid-layout ref="gridLayOut" :table-options="tableOption" :table-data="tableData"
                    :table-loading="tableLoading" :data-total="page.total" :page="page" @page-current-change="onLoad"
                    @page-size-change="onLoad" @gird-handle-select-click="selectionChange" :cell-style="cellStyle">
                    <template #customBtn="{ row }">
                        <el-button type="text" size="small" @click="headAdd(row, 'view')">查看</el-button>
                        <el-button style="margin: 0 3px" type="text" size="small"
                            @click="headAdd(row, 'edit')">编辑</el-button>
                        <el-button style="margin: 0 3px" type="text" size="small" @click="rowRemove(row)">删除</el-button>
                    </template>
                </grid-layout>
            </el-main>
        </el-container>

    </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree";

import { mapGetters } from "vuex";

import * as API from "@/api/dbtAppliedTatistics/index";
import {
    selectChildren
} from "@/api/system/dept";
export default {
    components: {
        CommonTree,
        GridLayout,
        HeadLayout,
    },
    data() {
        return {
            tableData: [],
            selectionList: [],
            tableLoading: false,
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0,
            },
            searchTitle: 'title',
            deptId: "",
            defaultProps: {
                children: "children",
                label: "title",
            },
        };
    },
    computed: {
        ...mapGetters(["userInfo"]),
        ids() {
            let ids = [];
            this.selectionList.forEach((ele) => {
                ids.push(ele.id);
            });
            return ids.join(",");
        },
        headBtnOptions() {
            return [
                {
                    label: this.$t(`cip.cmn.btn.addBtn`),
                    emit: "head-add",
                    type: "button",
                    icon: "",
                },
                {
                    label: this.$t("cip.cmn.btn.delBtn"),
                    emit: "head-romve",
                    type: "button",
                    icon: "",
                },
            ];
        },
        tableOption() {
            return {
                linklabel: "eventCode",
                menuFixed: "right",
                menuWidth: 150,
                column: [
                    {
                        label: "模块名称",
                        prop: "moduleName",
                        align: "left",
                        overHidden: true,
                        width: 200,
                    },
                    {
                        label: "负责人",
                        prop: "director",
                        align: "left",
                        overHidden: true,
                        width: 80,
                    },
                    {
                        label: "表编码",
                        prop: "tableName",
                        align: "left",
                        overHidden: true,
                        width: 120,
                    },
                    {
                        label: "项目id字段",
                        prop: "projectFields",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "对应一级菜单",
                        prop: "firstLevelMenu",
                        align: "center",
                        width: 200,
                        overHidden: true,
                    },
                    {
                        label: "排序号",
                        prop: "soft",
                        align: "center",
                        width: 200,
                        overHidden: true,
                    },
                ],
            };
        },
        searchColumns() {
            return [
                {
                    label: "模块名称或负责人",
                    prop: "director",
                    span: 3,
                    placeholder: "请输入模块名称或负责人",
                },
                {
                    label: "一级模块",
                    prop: "firstLevelMenu",
                    span: 3,
                    placeholder: "请输入一级模块",
                },
            ];
        },
    },
    mounted() {
        this.deptId = this.userInfo.dept_id;
        this.onLoad(this.page, {});
    },
    methods: {
        treeNodeClick(node) {
            this.deptId = node.id;
            this.page.currentPage = 1;
            this.onLoad(this.page, {});
        },
        // 搜索
        gridHeadSearch(searchForm) {
            this.page.currentPage = 1;
            this.onLoad(this.page, searchForm);
        },
        // 清空
        gridHeadEmpty(searchForm) {
            this.page.currentPage = 1;
            this.$refs.gridHeadLayout.searchForm = {};
            this.deptId = this.userInfo.dept_id;
            this.onLoad(this.page, searchForm);
        },
        // 获取列表数据
        onLoad(page, params) {
            let that = this;
            this.page = page;
            // this.tableLoading = true;
            API.epeventPage({
                current: page.currentPage,
                size: page.pageSize,
                ...params,
                createDept: this.deptId
            }).then((res) => {
                console.log("THAT", this)
                if (res.data.code == 200) {
                    that.tableLoading = false;
                    var records = res.data.data.records;
                    that.tableData = records;
                    that.page.total = res.data.data.total;
                    that.$refs.gridLayOut.page.total = res.data.data.total;
                } else {
                    that.tableLoading = false;
                }
            });

        },
        // 选择列表数据
        selectionChange(list) {
            this.selectionList = list;
        },
        // 批量删除
        headRomve() {
            let selectionList = this.$refs.gridLayOut.selectionList;
            if (selectionList.length === 0) {
                this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
                return;
            }
            this.$confirm("确定删除当前数据?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
              console.log(this.ids)
                API.removeByIds(this.ids).then(() => {
                    this.onLoad(this.page);
                    this.$message.success("操作成功！");
                });
            });
        },
        // 单条删除
        rowRemove(row, index) {
            let ids = `${row.id}`
            this.$confirm("确定删除当前数据?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                API.removeByIds(ids).then(() => {
                    this.onLoad(this.page);
                    this.$message.success("操作成功！");
                });
            });
        },
        // 新增
        search() {

        },
        headAdd(row, type) {
            if (type == 'view') {
                this.$router.push({
                    path: `/dbtAppliedTatistics/configure/edit`,
                    query: {
                        type: "view",
                        row: row
                    },
                });
            } else if (type == 'edit') {
                this.$router.push({
                    path: `/dbtAppliedTatistics/configure/edit`,
                    query: {
                        type: "edit",
                        row: row
                    },
                });
            } else {
                this.$router.push({
                    path: `/dbtAppliedTatistics/configure/edit`,
                    query: {
                        type: "add",
                    },
                });
            }
        },
        cellStyle(data) {
            const { row } = data;
            let _style = "";
            if (row.draft == 1 && !row.sameDay) _style = "color:#ffce7b";
            return _style;
        },
    },
};
</script>

<style lang="scss" scoped></style>